.loader {
  position: fixed;
  top: -1%;
  left: -1%;
  right: -1%;
  bottom: -1%;
  z-index: 99999;
  background-color: $dark;

  .txt {
    @include absolute-center(center);
    text-align: center;
    @include font-base;
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
    // text-transform: uppercase;
    font-size: font-calc(100px);
    @include break(mobile) {
      font-size: font-calc(50px);
    }
    color: $mauve;
    animation: txtAnim 4s 0.5s linear infinite;
    transform-style: preserve-3d;
  }
}

.transition {
  position: fixed;
  top: -1%;
  left: -1%;
  right: -1%;
  bottom: -1%;
  z-index: 99999;
  background-color: $bg;
  display: none;

  .txt {
    @include absolute-center(center);
    text-align: center;
    @include font-base;
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
    // text-transform: uppercase;
    font-size: font-calc(100px);
    @include break(mobile) {
      font-size: font-calc(50px);
    }
    color: $dark;
    animation: txtAnimT 4s 0.2s linear infinite;
    transform-style: preserve-3d;
    white-space: nowrap;
  }
}

@keyframes txtAnim {
  0%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
    color: $mauve;
  }
  15%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
    color: $mauve;
  }
  20%{
    font-variation-settings: "wght" 560, "wdth" 70, "ital" 50;
    color: $green;
  }
  35%{
    font-variation-settings: "wght" 560, "wdth" 70, "ital" 50;
    color: $green;
  }
  40%{
    font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
    color: $red;
  }
  55%{
    font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
    color: $red;
  }
  60%{
    font-variation-settings: "wght" 310, "wdth" 25, "ital" 0;
    color: $yellow;
  }
  75%{
    font-variation-settings: "wght" 310, "wdth" 25, "ital" 0;
    color: $yellow;
  }
  80%{
    font-variation-settings: "wght" 440, "wdth" 70, "ital" 50;
    color: $blue
  }
  95%{
    font-variation-settings: "wght" 440, "wdth" 70, "ital" 50;
    color: $blue
  }
  100%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
    color: $mauve;
  }
}

@keyframes txtAnimT {
  0%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
  }
  15%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
  }
  20%{
    font-variation-settings: "wght" 560, "wdth" 70, "ital" 50;
  }
  35%{
    font-variation-settings: "wght" 560, "wdth" 70, "ital" 50;
  }
  40%{
    font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
  }
  55%{
    font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
  }
  60%{
    font-variation-settings: "wght" 310, "wdth" 25, "ital" 0;
  }
  75%{
    font-variation-settings: "wght" 310, "wdth" 25, "ital" 0;
  }
  80%{
    font-variation-settings: "wght" 440, "wdth" 70, "ital" 50;
  }
  95%{
    font-variation-settings: "wght" 440, "wdth" 70, "ital" 50;
  }
  100%{
    font-variation-settings: "wght" 560, "wdth" 170, "ital" 50;
  }
}
