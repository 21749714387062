@import 'partials/_mixin';
@import 'partials/_function';

@import './base/fonts';
@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/cookies';
@import './base/layout';

@import './common/animation';
@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import './pages/home';
@import './pages/apropos';
@import './pages/contact';
@import './pages/single-artist';
@import './pages/archive-artist';
@import './pages/archive-concours';
@import './pages/single-concours';
@import './pages/quatrecentquatre';
@import './pages/concert';
@import './pages/single-concert';
@import './pages/custom-login';
@import './pages/custom-register';
@import './pages/custom-user-account';
