/*  --- Variable ---  */



/* --- Variables for fonction --- */

$font-size: 18px;
$mockup-size: 24px;

//COLOR
$black: #000000;
$dark: #232323;
$grey: #2E2E2E;
$light-grey: #E4E4E4;
$white: #ffffff;
$dark-blue: #000047;
$bg: #EFEFEF;

$blue: #2346DC;
$green: #23D78C;
$mauve: #A238FF;
$yellow: #FFD700;
$red: #FF1E0A;
