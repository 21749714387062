.post-type-archive-artistes header .menu-primary-navigation-container li:first-of-type a {
  font-variation-settings: "wght" 400, "wdth" 100, "ital" 60;
  pointer-events: none;
}

.oxo-view.template-archive-artiste {

  @include break(small-screen) {
    margin-top: 100px;
  }

  .sec2 {

    .filters {
      border-top: 4px solid $dark;
      border-bottom: 4px solid $dark;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      @include break(mobile) {
        flex-direction: row;
      }

      .search {
        @include break(mobile) {
          width: 48%;
        }

        .search-icon {
          position: relative;
          cursor: pointer;
          padding: 15px 10px 15px 30px;
          @include break(tablet) {
            display: none;
          }
          .close {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            opacity: 0;
            transition: opacity 0.4s;
          }
          .open {
            transition: opacity 0.4s;
            transform: translateY(5px);
          }

          &.active .close {
            opacity: 1;
          }
          &.active .open {
            opacity: 0;
          }
        }
        .search-bar {
          position: absolute;
          display: none;
          top: 0;
          bottom: 0;
          background-color: $bg;
          right: 255px;
          left: 85px;
          z-index: 9;
          @include break(tablet) {
            display: block;
            left: 0;
            right: auto;
            width: 100%;
            position: relative;
          }

          form {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            @include break(tablet) {
              display: block;
              position: relative;
              top: auto;
              transform: translateY(0%);
            }
          }

          input {
            border-radius: 0;
            border: 2px solid $dark;
            font-size: 1em;
            padding: 8px 15px;
            width: 70%;
            &:focus {
              outline: none;
            }
            @include break(tablet) {
              display: block;
              width: 100%;
            }
          }
        }
      }

      .filter-alpha {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-shrink: 1;
        @include break(small-screen) {
          display: none;
        }

        a, div{
          font-size: font-calc(24px);
          text-transform: uppercase;
          // Important
            font-weight: normal;
          // Important
          font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
          text-decoration: none;
          margin-left: 0.45em;
          margin-right: 0.45em;
          line-height: 2em;
          transition: all 0.4s;
        }
        div, .not-active {
          opacity: 0.5;
          pointer-events: none;
        }
        a.active:hover, a.current {
          // Important
            font-weight: normal;
          // Important
          font-size: font-calc(36px);
          font-variation-settings: "wght" 700, "wdth" 100, "ital" 0;
          transform-origin: center;
        }
      }

      .filter-tour {
        padding-left: 50px;
        border-left: 2px solid $dark;
        @include break(tablet) {
          border-left: none;
        }
        @include break(mobile) {
          padding-left: 0px;
          width: 48%;
        }

        .btn {
          width: 200px;
          justify-content: center;
          @include break(mobile) {
            width: 100%;
            max-width: 100%;
            display: block;
            padding: 15px;
          }


          &.active {
            background-color: $dark;
            color: $white;
          }
        }
      }
    }
  }

  .sec3 {
    display: flex;
    padding-top: 80px;
    .container-flex {
      width: 100%;
    }
    .left {
      width: 300px;
      top: 200px;
      position: sticky;
      @include break(tablet) {
        display: none;
      }

      div {
        // Important
          font-weight: normal;
        // Important
        text-transform: uppercase;
        font-size: font-calc(150px);
        font-variation-settings: "wght" 600, "wdth" 60, "ital" 0;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
      }
    }
    .right {
      margin-left: 250px;
      @media only screen and (max-width: 1400px) {
        margin-left: 150px;
      }

      @include break(tablet) {
        margin-left: 0px;
      }
      >div {
        display: grid;
        grid-template-columns: repeat(auto-fill, 280px);
        justify-content: space-between;
        grid-gap: 20px;
        @media only screen and (max-width: 1400px) {
          grid-template-columns: repeat(auto-fill, 245px);
        }
        @include break(small-screen) {
          grid-gap: 10px;
          grid-template-columns: repeat(auto-fill, 220px);
        }
        @include break(tablet) {
          display: block;

          a:last-of-type {
            border-bottom: 4px solid $dark!important;
            margin-bottom: 40px;
          }
        }
      }

      .tablet {
        // Important
          font-weight: normal;
        // Important
        text-transform: uppercase;
        font-size: font-calc(150px);
        font-variation-settings: "wght" 600, "wdth" 60, "ital" 0;
        line-height: 1em;
        text-align: center;
        @include break(tablet) {
          margin-bottom: 40px;
        }
      }

      a {
        border-bottom: 2px solid $dark;
        width: 280px;
        text-decoration: none;
        min-height: 355px;
        margin-bottom: 60px;
        @media only screen and (max-width: 1400px) {
          width: 245px;
        }
        @include break(small-screen) {
          width: 220px;
        }
        @include break(tablet) {
          display: flex;
          min-height: auto;
          width: 100%;
          align-items: center;
          padding-top: 15px;
          padding-bottom: 15px;
          border-top: 4px solid $dark;
          border-bottom: none;
          margin-bottom: 0px;
        }
        .img_wrap {
          width: 280px;
          height: 280px;
          position: relative;
          @media only screen and (max-width: 1400px) {
            width: 245px;
            height: 245px;
          }
          @include break(small-screen) {
            width: 220px;
            height: 220px;
          }
          @include break(tablet) {
            width: 100px;
            height: 100px;
            margin-right: 25px;
          }
        }
        .img {
          width: 280px;
          height: 280px;
          position: relative;
          filter: grayscale(1);
          transition: all 0.7s;
          @media only screen and (max-width: 1400px) {
            width: 245px;
            height: 245px;
          }
          @include break(small-screen) {
            width: 220px;
            height: 220px;
          }
          @include break(tablet) {
            width: 100px;
            height: 100px;
          }

          picture {
            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .tour-bandeau {
          position: absolute;
          z-index: 9;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          overflow: hidden;
          background-color: $dark;
          padding-top: 3px;
          padding-bottom: 2px;
          .bandeau {
            display: inline-flex;
            width: 100vw;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
            transform-style: preserve-3d;

            .line {
              display: block;
              height: 2px;
              width: 4vw;
              background-color: $dark;
              @include break(mobile) {
                width: 2vw;
              }
            }

            .txt {
              white-space: nowrap;
              margin-left: 15px;
              font-size: font-calc(18px);
              font-variation-settings: "wght" 400, "wdth" 100, "ital" 0;
              text-transform: uppercase;
              @include break(tablet) {
                font-size: font-calc(14px);
              }
            }
          }
        }
        h4 {
          padding-top: 5px;
          // Important
            font-weight: normal;
          // Important
          text-transform: uppercase;
          font-size: font-calc(36px);
          font-variation-settings: "wght" 600, "wdth" 60, "ital" 0;
          padding-right: 20px;
          transition: all 0.4s;
          line-height: 1.2em;
        }

        &:hover .img{
          filter: grayscale(0);
        }

        &.hover_blue:hover h4 {
          color: $blue;
        }
        &.hover_green:hover h4 {
          color: $green;
        }
        &.hover_mauve:hover h4, &.hover_pink:hover h4 {
          color: $mauve;
        }
        &.hover_yellow:hover h4 {
          color: $yellow;
        }
        &.hover_red:hover h4 {
          color: $red;
        }

      }
    }
  }
}
