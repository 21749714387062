.oxo-view.template-home {

  .sec1 {
    padding-top: 120px;

    .container {
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
        flex-direction: column;
      }
    }

    .left {
      width: 35%;
      padding-bottom: 40px;
      border-bottom: 2px solid $dark;
      @include break(tablet) {
        width: 100%;
        padding-bottom: 60px;
      }

      .focus {
        width: 100%;
        border-top: 2px solid $dark;
        border-bottom: 2px solid $dark;
        margin-bottom: 40px;
        span {
          display: inline-block;
          background-color: $dark;
          color: $white;
          text-transform: uppercase;
          padding: 0.5em 1.2em 0.35em 1.2em;
          font-size: font-calc(20px);
          letter-spacing: 0.25em;
          font-weight: normal;
          @include font-clarendon;
        }
      }
      h1 {
        @include font-base;
        font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
        text-transform: uppercase;
        font-size: font-calc(85px);
        letter-spacing: 0;
        line-height: 1em;
        padding-bottom: 10px;
        @include break(tablet) {
          text-align: left;
          font-size: font-calc(70px);
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;

        .open_modal {
          width: 120px!important;
          height: 64px;
          cursor: pointer;
          border-width: 2px;

          svg {
            width: 120px;
            height: 64px;
          }

          @include break(small-screen) {
            width: 80px;
          }
        }
        .btn {
          @include break(small-screen) {
            height: 50px;
            font-size: 14px;
            padding: 0px 20px;
            .arrow {
              width: 25px;
              margin-left: 8px;
            }
          }
        }
      }

    }
    .right {
      width: 60%;
      @include break(tablet) {
        width: 100%;
      }
      .img {
        width: 100%;
        height: auto;
        position: relative;
        padding-bottom: 68%;
        @include break(tablet) {
          padding-bottom: 87%;
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

.bandeau_container {
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  @include break(tablet) {
    margin-top: 8.5vw;
    margin-bottom: 8.5vw;
  }
  @include break(mobile) {
    margin-top: 12vw;
    margin-bottom: 12vw;
  }

  .bandeau {
    color: $blue;
    .line {
      background-color: $blue;
    }
  }
}


.sec2 {

  .concert_wrap {
    border-top: 2px solid $dark;
  }

  .concert {
    border-bottom: 2px solid $dark;
  }

  .concert_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .date {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-right: 65px;
    margin-right: 65px;
    border-right: 2px solid $dark;
    width: 290px;
    @include break(small-screen) {
      padding-right: 30px;
      margin-right: 40px;
      width: 250px;
    }
    @include break(tablet) {
      padding-right: 15px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 0px;
    }
    @include break(mobile) {
      width: 95px;
    }
  }

  .concert_info {
    width: 100%;
    flex-shrink: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 0;
    text-decoration: none;
    margin-right: 60px;
    @include break(tablet) {
      padding-top: 16px;
      padding-bottom: 0px;
      margin-right: 0px;
    }

    h3 {
      position: relative;
      z-index: 1;
      font-weight: normal;
      font-variation-settings: "wght" 440, "wdth" 48, "ital" 0;
      text-transform: uppercase;
      font-size: font-calc(90px);
      line-height: 1em;
      background-color: $dark;
      background-image: linear-gradient(-90deg, $dark 0% 50%, $dark);
      background-size: 220% 100%;
      background-position: top right;
      color: transparent;
      background-clip: text;
      transition: all 0.6s;
      @include break(mobile) {
        font-size: font-calc(40px);
      }
    }

    p {
      position: relative;
      z-index: 1;
      background-color: $dark;
      color: $white;
      display: inline-block;
      line-height: 1em;
      vertical-align: middle;
      text-transform: uppercase;
      padding: 0.5em 0.3em 0.3em 0.3em;
      @include font-clarendon-bold;
      @include break(mobile) {
        font-size: 10px;
        @include font-clarendon;
        margin-right: 20px;
        letter-spacing: 3px;
        display: inline;
      }
    }

    &.hover_blue h3{
      background-image: linear-gradient(90deg, $blue 0% 50%, $dark 50%);
    }
    &.hover_green h3{
      background-image: linear-gradient(90deg, $green 0% 50%, $dark 50%);
    }
    &.hover_mauve h3, &.hover_pink h3{
      background-image: linear-gradient(90deg, $mauve 0% 50%, $dark 50%);
    }
    &.hover_yellow h3{
      background-image: linear-gradient(90deg, $yellow 0% 50%, $dark 50%);
    }
    &.hover_red h3{
      background-image: linear-gradient(90deg, $red 0% 50%, $dark 50%);
    }

    &:hover h3 {
      font-variation-settings: "wght" 445, "wdth" 53, "ital" 75;
      background-position: top left;
    }
  }

  .concert_item-link {
    .btn {
      width: 255px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: font-calc(50px);
      font-weight: normal;
      font-variation-settings: "wght" 200, "wdth" 40, "ital" 0;
      @include break(tablet) {
        width: 100%;
        height: 60px;
        justify-content: center;
        font-size: font-calc(35px);
        margin-top: 20px;
        margin-bottom: 20px;
      }

      svg {
        width: 60px;
        @include break(tablet) {
          width: 30px;
        }
        g {
          stroke: $white;
        }
      }

      &.disabled-link {
        pointer-events: none;
        cursor: default;
      }

      &:hover {
        font-variation-settings: "wght" 205, "wdth" 50, "ital" 0!important;
      }

      &-cancel {
        font-size: font-calc(35px);
        line-height: 1.2em;
        justify-content: center;
        align-items: center;
        border: 4px solid $dark;
        flex-direction: column;
        // pointer-events: none;
        @include break(tablet) {
          font-size: font-calc(25px);
        }
      }
    }
  }

  .more {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: font-calc(20px);
    font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      font-variation-settings: "wght" 600, "wdth" 105, "ital" 0;
    }

    svg {
      margin-left: 20px;
      margin-right: 20px;
      height: 30px;
    }

  }
}

}
