html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $bg;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

body {
  &.overflow{
    overflow: hidden;
  }
  background-color: $bg;
  color: $dark;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  @include break(small-screen){
    font-size: 16px;
  }
  @include break(tablet){
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-txt;

  &.body-select-blue *::selection {
    background: $blue;
    color: $white;
  }
  &.body-select-green *::selection {
    background: $green;
    color: $white;
  }
  &.body-select-mauve *::selection, &.select-pink *::selection {
    background: $mauve;
    color: $white;
  }
  &.body-select-yellow *::selection {
    background: $yellow;
    color: $white;
  }
  &.body-select-red *::selection {
    background: $red;
    color: $white;
  }
}

.select-blue *::selection {
  background: $blue!important;
  color: $white;
}
.select-green *::selection {
  background: $green!important;
  color: $white;
}
.select-mauve *::selection, .select-pink *::selection {
  background: $mauve!important;
  color: $white;
}
.select-yellow *::selection {
  background: $yellow!important;
  color: $white;
}
.select-red *::selection {
  background: $red!important;
  color: $white;
}


.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}
.tablet {
  display: none;
  @include break(tablet) {
    display: block;
  }
}
.desktop-tab{
  @include break(tablet) {
    display: none;
  }
}

.wrap {
  padding-right: 120px;
  padding-top: 72px;
  position: relative;
  @include break(small-screen) {
    padding-top: 66px;
  }
  @include break(tablet) {
    padding-right: 0px;
    padding-bottom: 75px;
  }
}

h1 {
  @include font-base;
  font-variation-settings: "wght" 440, "wdth" 140, "ital" 0;
  text-transform: uppercase;
  font-size: font-calc(70px);
  color: $dark;
  letter-spacing: 0;
  line-height: 1.2em;
  max-width: 550px;
  margin: 0;
  @include break(tablet) {
    font-size: font-calc(56px);
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
}

h5 {
  font-size: font-calc(30px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  // Important
    font-weight: normal;
  // Important
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
}

h6 {
  font-size: font-calc(16px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  // Important
    font-weight: normal;
  // Important
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
}

small {
  // Important
    font-weight: normal;
  // Important
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
  font-size: font-calc(14px);
}

.title_line {
  margin-top: 120px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-height: 700px) {
    margin-top: 40px;
    margin-bottom: 40px;
    h1 {
      font-size: font-calc(60px);
    }
  }

  @include break(small-screen) {
    margin-top: 40px;
    margin-bottom: 40px;
    h1 {
      font-size: font-calc(60px);
    }
  }

  .line {
    height: 2px;
    background-color: $dark;
    width: 150px;
    border-radius:10px;
    @include break(mobile) {
      display: none;
    }
  }
}

a {
  color: inherit;
}

.date{
  display: flex;
  font-size: 100px;
  @include break(small-screen) {
    font-size: 60px;
  }
  @include break(mobile) {
    font-size: 30px;
  }

  .date_l {
    width: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include break(small-screen) {
      width: 100px;
    }
    @include break(mobile) {
      align-items: flex-start;
      width: 60px;
    }
  }

  .date_month {
    @include font-base;
    font-variation-settings: "wght" 700, "wdth" 0, "ital" 0;
    font-size: 0.7em;
    @supports (font-variation-settings: normal) {
      font-size: 1em;
    }
    line-height: 1em;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
    text-align: center;
  }

  .date_day {
    @include font-base;
    font-variation-settings: "wght" 600, "wdth" 0, "ital" 0;
    font-size: 0.2em;
    @supports (font-variation-settings: normal) {
      font-size: 0.52em;
    }
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1em;
  }

  .date_numb {
    @include font-base;
    font-variation-settings: "wght" 240, "wdth" 4, "ital" 0;
    font-size: 1.2em;
    @supports (font-variation-settings: normal) {
      font-size: 1.8em;
    }
    padding-left: 0.12em;
    line-height: 1em;
    @include break(mobile) {
      padding-left: 0;
      font-size: 1.8em;
    }
  }
}

.hover_img-cursor {
  position: fixed;
  display: none;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.3s;
  width: 360px;
  height: 430px;
  background-position: center;
  background-size: cover;
  &.reverse {
    transform: translateX(-100%);
  }
  &.reverseH {
    transform: translateY(-100%);
  }
  &.reverseH.reverse {
    transform: translate(-100%,-100%);
  }
}


.txt_blue {
  color: $blue;
}
.txt_green {
  color: $green;
}
.txt_mauve, .txt_pink {
  color: $mauve;
}
.txt_yellow {
  color: $yellow;
}
.txt_red {
  color: $red;
}


.bg_blue {
  background-color: $blue;
  color: $white;
}
.bg_green {
  background-color: $green;
  color: $white;
}
.bg_mauve, .bg_pink {
  background-color: $mauve;
  color: $white;
}
.bg_yellow {
  background-color: $yellow;
  color: $white;
}
.bg_red {
  background-color: $red;
  color: $white;
}
.bg_black {
  background-color: $black;
  color: $white;
}
.bg_dark {
  background-color: $dark;
  color: $white;
}


.bandeau_container {
  width: 100vw;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 2vw;
  margin-bottom: 2vw;

  @include break(tablet) {
    transform: scale(2);
  }
  @include break(mobile) {
    transform: scale(3);
  }
  .bandeau {
    display: inline-flex;
    width: 100vw;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    transform-style: preserve-3d;
    font-variation-settings: "wght" 600, "wdth" 60, "ital" 0;
    font-size: font-calc(115px);
    @media only screen and (min-width: 1600px) {
      font-variation-settings: "wght" 610, "wdth" 60, "ital" 0;
      font-size: font-calc(125px);
    }
    @media only screen and (min-width: 1800px) {
      font-variation-settings: "wght" 610, "wdth" 80, "ital" 0;
      font-size: font-calc(135px);
    }
    @include break(small-screen) {
      font-variation-settings: "wght" 600, "wdth" 60, "ital" 0;
    font-size: 6vw;
    }

    .line {
      display: block;
      height: 0.05em;
      width: 0.3em;
      background-color: $dark;
      @include break(mobile) {
        width: 2vw;
      }
    }

    .txt {
      line-height: 1em;
      text-transform: uppercase;
    }
  }
}


.btn {
  font-weight: normal;
  font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
  background-color: $white;
  color: $dark;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  padding: 14px 25px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.6s;
  &:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    transform: translate(-101%);
    transition: all 0.4s;
  }

  &_grey {
    background-color: rgba($dark,0.1);
    &:before {
      background-color: $dark;
    }
  }
  &_dark-grey {
    background-color: $dark;
    color: $white;
    &:before {
      background-color: $light-grey;
    }
  }

  &.hover_blue{
    @include break(tablet) {
      background-color: $blue;
    }
    &:before {
      background-color: $blue;
    }
  }
  &.hover_green {
    @include break(tablet) {
      background-color: $green;
    }
    &:before {
      background-color: $green;
    }
  }
  &.hover_mauve, &.hover_pink {
    @include break(tablet) {
      background-color: $mauve;
    }
    &:before {
      background-color: $mauve;
    }
  }
  &.hover_yellow {
    @include break(tablet) {
      background-color: $yellow;
    }
    &:before {
      background-color: $yellow;
    }
  }
  &.hover_red {
    @include break(tablet) {
      background-color: $red;
    }
    &:before {
      background-color: $red;
    }
  }

  &:hover {
    @include break(desktop-only) {
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      color: $white;
      &:before {
        transform: translate(0%);
      }
    }
  }

  .arrow {
    width: 40px;
    margin-left: 10px;
  }

}

.btn_round {
  border: 1px solid $dark;
  width: 69px;
  height: 37px;
  display: block;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  &:before {
    content: " ";
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    transform: scale(0);
    transition: all 0.2s;
  }

  img, svg {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 50%;
    left: 50%;
    transition: all 0.4s;
    transform: translate(-50%, -50%);

    &.hover {
      z-index: 2;
      opacity: 0;
    }
  }

  svg path{
    transition: all 0.4s;
  }

  &_white {
    border: 1px solid $white;
    &:before {
      background-color: $white;
    }
  }

  &:hover {
    &:before {
      transform: scale(0.9);
    }
    .hover {
      opacity: 1;
    }
    svg path {
      fill: $white;
    }
  }
  &_white:hover svg path {
    fill: $dark;
  }
}

.modal_video {
  position: fixed;
  background-color: rgba($dark, 0.9);
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: none;

  .video {
    @include absolute-center(center);
    width: 80%;
    padding-bottom: 45%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.default-template, .legal-notices {
  padding-top: 120px;
  padding-bottom: 120px;

  @include break(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  h1 {
    padding: 0;
    margin-bottom: 60px;
    width: 100%;

    @include break(mobile) {
      font-size: 1.6em;
      text-align: left;
    }
  }
}

@keyframes fadeIn {
  0%{
    opacity: 0;
    transform: scale(1.01);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInO {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
  position: fixed;
}