.oxo-view.template-apropos {

  @include break(small-screen) {
    margin-top: 100px;
  }

  .sec1 .nxt {
    position: absolute;
    top: 50%;
    left: 60%;
    z-index: 3;
    width: 125px;
    opacity: 0;
    height: auto;
    pointer-events: none;
    transform: translate(-50%,-50%);

    @include break(tablet) {
      display: none;
    }

    &.reverse {
      transform: rotate(-180deg) translate(50%,50%);
    }
  }
  .slider_ap {
    position: relative;
    cursor: none;

    .map-left {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      z-index: 2;
    }
    .map-right {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      z-index: 2;
    }

    .slider_wrapper {
      padding-bottom: 46%;
      width: 100%;
      overflow: hidden;
      position: relative;

      .slide {
        width: 69%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transform: translateX(220%);
        transform-style: preserve-3d;

        &:nth-of-type(1) {
          transform: translateX(0%);
        }
        &:nth-of-type(2) {
          transform: translateX(110%);
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .sec2 {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-left: calc((100vw - 1310px)/2);
    width: 70%;
    @media only screen and (max-width: 1430px) {
      padding-left:5%;
    }
    @include break(tablet) {
      padding-left: 5%;
      padding-right: 5%;
      width: 100%;
      margin-top: 80px;
      margin-bottom: 80px;
    }

    h2 {
      padding-bottom: 30px;
      @include font-bold;
      text-transform: uppercase;
      font-size: font-calc(60px);
      font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
    }
  }

.bandeau {
  color: $blue;
  .line {
    background-color: $blue;
  }
}
  .bandeau_container .bandeau .line:last-of-type, .bandeau_container .bandeau .txt:last-of-type {
    @include break(mobile) {
      display: none;
    }
  }

  .sec3 {
    margin-top: 100px;
    margin-bottom: 120px;
    @include break(tablet) {
      margin-top: 80px;
      margin-bottom: 100px;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @include break(mobile) {
        width: 100%;
      }
    }

    .member {
      width: 280px;
      margin-bottom: 140px;
      position: relative;
      @include break(tablet) {
        margin-bottom: 120px;
        width: 50%;
      }
      .img {
        width: 100%;
        padding-bottom: 133%;
        position: relative;
        transition: all 0.7s;
        filter: grayscale(1);
        &:hover {
          filter: grayscale(0);
        }
        @include break(tablet) {
          filter: grayscale(0);
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .box {
        width: 87.7%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 15px 20px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%,-40px);

        h4 {
          @include font-bold;
          text-transform: uppercase;
          font-size: font-calc(22px);
          width: 100%;
          position: relative;
          margin-bottom: 10px;
          padding-bottom: 4px;
          @include break(tablet) {
            font-size: font-calc(18px);
          }

          &:after {
            content:'';
            display: block;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            background:$white;
            height:2px;
            border-radius:10px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          @include break(tablet) {
            font-size: 12px;
          }
          small {
            display: block;
          }
        }
      }
      &:nth-child(6n-5) .box {
        @extend .bg_green;
      }
      &:nth-child(6n-4) .box {
        @extend .bg_blue;
      }
      &:nth-child(6n-3) .box {
        @extend .bg_red;
      }
      &:nth-child(6n-2) .box {
        @extend .bg_yellow;
      }
      &:nth-child(6n-1) .box {
        @extend .bg_black;
      }
      &:nth-child(6n) .box {
        @extend .bg_mauve;
      }
    }

    .empty {
      width: 280px;
      height: 0;
    }

  }

  .sec4 {
    background-color: $blue;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      font-size: font-calc(60px);
      font-variation-settings: "wght" 440, "wdth" 140, "ital" 0;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      @include break(tablet) {
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .btn_round {
        width: 188px;
        height: 100px;
        margin-left: 50px;
        @include break(tablet) {
          margin-left: 0px;
          transform: scale(0.5);
        }
        svg {
          width: 188px;
          height: 100px;
        }

        &:hover {
          g {
            stroke: $blue;
          }
          path {
            fill: none;
          }
        }
      }
    }
  }
}
