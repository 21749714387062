.oxo-view.template-register {
  min-height: calc(100vh - 380px);
  padding-top: 120px;
  padding-bottom: 120px;
  @include break(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    @include break(tablet) {
      display: block;
    }
    h1 {
      width: 45%;
      margin-bottom: 0px;
      @include break(tablet) {
        width: 100%;
      }
    }
    p {
      @include font-regular;
      width: 45%;
      text-align: right;
      @include break(tablet) {
        width: 100%;
        text-align: left;
      }
    }
  }

  h1 {
    @include font-base;
    font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
    font-size: font-calc(60px);
    text-align: left;
    margin-bottom: 40px;
    @include break(tablet) {
      width: 100%;
    }
  }

  .desc {
    @include font-medium;
    margin-bottom: 0px;
  }


  .ariane_form {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 20px;
    border-top: 4px solid $dark;
    border-bottom: 4px solid $dark;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;
    @include break(tablet) {
      align-items: flex-start;
      margin-bottom: 0px;
      margin-top: 40px;
    }

    .line {
      background-color: $dark;
      width: 70px;
      height: 2px;
      margin-left: 10px;
      margin-right: 10px;
      @include break(tablet) {
        display: none;
      }
    }

    .form_sec_numb {
      margin-left: 10px;
      margin-right: 10px;
      width: 120px;
      text-align: center;
      @include break(tablet) {
        margin-left: 10px;
        margin-right: 10px;
        width: 80px;
      }

      .number {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        border: 2px solid $dark;
        width: 60px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        text-align: center;
        font-size: 14px;
        @include font-bold;
        transition: all 0.4s;
      }
      p {
        @include font-regular;
        line-height: 1em;
        font-size: 16px;
        @include break(tablet) {
          font-size: 12px;
        }
      }

      &.active {
        &.form_sec_numb1 .number {
          background-color: $green;
        }
        &.form_sec_numb2 .number {
          background-color: $yellow;
        }
        &.form_sec_numb3 .number {
          background-color: $red;
        }
      }
    }

  }

  .form {
    position: relative;
    width: 100%;
    padding-bottom: 110px;

    label {
      @include font-medium;
      font-size: 12px;
      letter-spacing: 0;
      text-transform: uppercase;
      display: block;
      padding-top: 40px;
      width: 100%;

      &.nl_label {
        padding-top: 20px;
        cursor: pointer;
      }
    }
    .two-col-label {
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
       flex-direction: column;
      }
      >div {
        width: 45%;
        display: flex;
        @include break(tablet) {
          width: 100%;
        }

        svg {
          margin-left: 10px;
        }

        &.right {
          @include font-txt;
          text-transform: none;
          justify-content: flex-end;
          transition: all 0.3s;
          @include break(tablet) {
            justify-content: flex-start;
          }
        }
      }
    }
    .two_col {
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
       flex-direction: column;
      }
      >div {
        width: 45%;
        @include break(tablet) {
          width: 100%;
        }
      }
    }
    .form1, .form2 {
      input, select {
        margin: 0;
        background-color: rgba($white, 0);
        border: 2px solid $dark;
        outline: none!important;
        border-radius: 0;
        color: $dark;
        font-size: 16px;
        padding: 12px 15px;
        margin-top: 10px;
        width: 100%;
        appearance: none;
        transition: 0.4s;

        &:focus {
          background-color: rgba($white, 1);
        }

        &[type='checkbox'] {
          display: inline-block;
          width: auto;
          border: 2px solid $dark;
          outline: none!important;
          border-radius: 0;
          background-color: $bg;
          appearance: none;
          width: 15px;
          height: 15px;
          padding: 0;
          margin-right: 5px;
          margin-bottom: -2px;

          &:checked {
            background-color: $dark;
          }
        }
      }
      .date_wrap {
        display: flex;
      }
      .date_j, .date_m {
        width: 80px;
        margin-right: 20px;
      }
      .date_a {
        width: 120px;
      }
      select {
        background-image: url("../img/select_arrow.png");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 15px;
        background-size: 20px;
      }
    }


    input[type='submit'], button[type='submit'] {
      appearance: none;
      @include font-base;
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      background-color: $dark;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      padding: 14px 25px;
      width: 210px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      border: 2px solid $dark;
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }

    button[type='submit'] {
      width: auto;
      outline: none!important;
    }

    .login-submit {
      text-align: right;
      @include break(tablet) {
        text-align: left;
      }
    }

    .errorMessage {
      color: $red;
      font-size: 14px;
      margin-top: 5px;
      opacity: 0;
    }

    .acf-fields>.acf-field {
      padding: 0;
    }
    .select2-container--default {
      .select2-selection--multiple {
        background-color: rgba($white, 0);
        border: 2px solid $dark;
        border-radius: 0;
        font-size: 16px;
        padding: 5px 15px;
        height: 48px;
        transition: all 0.4s;
        display: flex;
        align-items: center;
        .select2-selection__choice {
          background-color: $dark;
          border: none;
          color: $white;
          padding: 6px 9px;
          margin-top: 1px;
        }
        .select2-search__field {
          height: 33px;
        }
      }
      &.select2-container--focus .select2-selection--multiple {
        background-color: rgba($white, 1);
      }
    }

    .form_sec {
      position: absolute;
      width: 100%;
      top:0;
      left: 0;
      right: 0;
      opacity: 0;
      display: none;

      &:first-of-type {
        position: relative;
        opacity: 1;
        display: block;
      }
    }

    .nav_form {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom:0;
      left: 0;
      right: 0;
      .btn_round {
        width: 120px;
        height: 70px;
        cursor: pointer;
        @include break(tablet) {
          width: 96px;
          height: 56px;
        }

        svg {
          width: 50px;
          height: auto;
          @include break(tablet) {
            width: 40px;
          }
        }
        &:hover svg path{
          fill: transparent;
          stroke: $white;
        }

        &.not-active {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      input[type='submit'] {
        display: none;
      }
    }
  }
  .btn {
    svg {
      width: 45px;
      height: auto;
      margin-left: 10px;
    }
    &:before {
      background-color: $white;
    }
    &:hover {
      color: $dark;
      svg path{
        stroke: $dark;
      }
    }
  }
}
