.oxo-view.template-contact {

  @include break(small-screen) {
    margin-top: 100px;
  }

  .title_line {
    position: relative;
    padding-bottom: 100px;
    @include break(small-screen) {
      padding-bottom: 40px;
    }
    @include break(tablet) {
      padding-bottom: 0px;
    }
    &:after {
      content:'';
      position:absolute;
      display: block;
      bottom:0;
      left:0;
      right:0;
      background:$dark;
      height:4px;
      border-radius:15px;
      @include break(tablet) {
        display: none;
      }
    }
  }

  .sec2 {
    margin-bottom: 120px;
    @include break(tablet) {
      margin-bottom: 100px;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: top;
      padding-right: 10%;
      @include break(tablet) {
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
      }

      .side {
        width: 25%;
        @include break(tablet) {
          width: 100%;
          border-top: 4px solid $dark;
          margin-top: 60px;
          padding-top: 60px
        }
        p {
          padding-bottom: 40px
        }
        >a {
          display: block;
          margin-bottom: 40px;
          text-decoration: none;
          transition: all 0.4s;

          &:hover {
            color: $blue;
          }
        }
      .medias {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
        .btn_round {
          flex-shrink: 0;
          margin-bottom: 40px;
          margin-right: 20px;
        }
      }
      .forms_container {
        width: 58%;
        @include break(tablet) {
          width: 100%;
        }

        .top_forms {
          padding-top: 15px;
          padding-bottom: 15px;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include break(tablet) {
            justify-content: flex-start;
          }

          &:before {
            content:'';
            display: block;
            position:absolute;
            top:0;
            left:0;
            right:0;
            background:$dark;
            height:2px;
            border-radius:10px;
          }
          &:after {
            content:'';
            display: block;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            background:$dark;
            height:2px;
            border-radius:10px;
          }


          h5 {
            @include font-bold;
            font-size: font-calc(26px);
            margin: 0;
            padding: 0;
            @include break(tablet) {
              margin-right: 40px;
            }
          }

        }
        .options_btn {
          display: flex;
          @include break(tablet) {
            flex-direction: column;
          }

          .btn {
            margin-left: 40px;
            cursor: pointer;
            text-align: center;
            @include break(tablet) {
              margin-top: 20px;
              margin-left: 0px;
            }

            &.active {
              background-color: $dark;
              color: $white;
              pointer-events: none;
            }
          }
        }

        .forms_wrap {
          position: relative;
        }

        input, textarea {
          margin: 0;
          background-color: $bg;
          border: 1px solid $dark;
          outline: none!important;
          border-radius: 0;
          color: $dark;
          font-size: 16px;
          padding: 12px 15px;
          margin-top: 10px;
          width: 100%;

          &[type='submit'] {
            font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
            background-color: $dark;
            color: $white;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 16px;
            padding: 14px 25px;
            width: 150px;
            cursor: pointer;
            position: relative;
            margin-top: 30px;
            transition: all 0.4s;
            &:hover {
              background-color: $white;
              color: $dark;
            }
          }
        }

        label {
          @include font-medium;
          font-size: 12px;
          letter-spacing: 0;
          text-transform: uppercase;
          display: block;
          padding-top: 40px;
          width: 100%;
        }

        span.wpcf7-form-control-wrap.recaptcha div {
          margin-top: 20px;
        }

        .form2 {
          display: none;
          opacity: 0;
          transform: translateX(100px);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
          border: none;
          float: left;
          padding-left: 0;
          margin-left: 0;
          padding-right: 0;
          margin-right: 0;
        }
        .wpcf7-not-valid-tip {
          padding-top: 5px;
        }
        .wpcf7 form.sent .wpcf7-response-output {
          border: none;
          position: absolute;
          top: -20px;
          background-color: rgba($bg, 0.8);
          left: -20px;
          right: -20px;
          bottom: -40px;
          color: $dark;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
        }
      }
    }
  }

}
