@font-face {
  font-family: 'Flexa';
  src: url("../fonts/GT-Flexa-var.ttf") format("truetype-variations"), url("../fonts/GT-Flexa-var.woff2") format("woff2");
}
// @font-face {
//  font-family: 'Flexa-Reg';
//  src: url('../fonts/GT-Flexa-Standard-Regular.woff2') format('woff2'), url('../fonts/GT-Flexa-Standard-Regular.woff') format('woff'), url('../fonts/GT-Flexa-Standard-Regular.otf') format('opentype');
// }
// @font-face {
//  font-family: 'Flexa-Reg-Bold';
//  src: url('../fonts/GT-Flexa-Standard-Bold.woff2') format('woff2'), url('../fonts/GT-Flexa-Standard-Bold.woff') format('woff'), url('../fonts/GT-Flexa-Standard-Bold.otf') format('opentype');
// }
// @font-face {
//  font-family: 'Flexa-Reg-Med';
//  src: url('../fonts/GT-Flexa-Standard-Medium.woff2') format('woff2'), url('../fonts/GT-Flexa-Standard-Medium.woff') format('woff'), url('../fonts/GT-Flexa-Standard-Medium.otf') format('opentype');
// }

//FONT
@mixin font-base {
  font-family: 'Flexa', Arial, Helvetica, sans-serif;
  font-weight: normal;
  @supports (font-variation-settings: normal) {
    font-family: 'Flexa', Arial, Helvetica, sans-serif;
    font-weight: normal;
  }
}
@mixin font-txt {
  font-family: 'Flexa', Arial, Helvetica, sans-serif;
  font-weight: normal;
  @supports (font-variation-settings: normal) {
    font-family: 'Flexa', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-variation-settings: "wght" 300, "wdth" 75, "ital" 0;
  }
}

@mixin font-regular {
  font-family: 'Flexa', Arial, Helvetica, sans-serif;
  font-weight: normal;
  @supports (font-variation-settings: normal) {
    font-family: 'Flexa', Arial, Helvetica, sans-serif;
    // Important
      font-weight: normal;
    // Important
    font-variation-settings: "wght" 400, "wdth" 100, "ital" 0;
  }
}

@mixin font-medium {
  font-family: 'Flexa', Arial, Helvetica, sans-serif;
  font-weight: 500;
  @supports (font-variation-settings: normal) {
    font-family: 'Flexa', Arial, Helvetica, sans-serif;
    // Important
      font-weight: normal;
    // Important
    font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
  }
}
@mixin font-bold {
  font-family: 'Flexa', Arial, Helvetica, sans-serif;
  font-weight: 700;
  @supports (font-variation-settings: normal) {
    font-family: 'Flexa', Arial, Helvetica, sans-serif;
    // Important
      font-weight: normal;
    // Important
    font-variation-settings: "wght" 700, "wdth" 100, "ital" 0;
  }
}

@mixin font-clarendon {
  font-family: clarendon-text-pro, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 4px;
  font-display: optional;
}

@mixin font-clarendon-bold {
  font-family: clarendon-text-pro, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 4px;
  font-display: optional;
}
