.oxo-view.template-single-artiste {

  aside {
    width: 410px;
    padding-right: 40px;
    border-right: 2px solid $dark;

    @include break(tablet) {
      width: 100%;
      border-right: none;
      padding-right: 0px;
    }
  }

  .content {
    width: 100%;
    flex-shrink: 1;
    padding-left: 40px;

    @include break(tablet) {
      padding-left: 0px;
    }
  }

  .sec1 {
    padding-top: 130px;

    @include break(tablet) {
      padding-top: 100px;
    }

    .scroll.tablet {
      @include break(tablet) {
        display: flex !important;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        padding: 0px 20px;

        svg {
          transform: scale(0.5);
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;

      @include break(tablet) {
        flex-direction: column;
      }

      .aside-title {
        border: none;

        p {
          // Important
          font-weight: normal;
          // Important
          font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
          text-transform: uppercase;
          font-size: font-calc(18px);
        }

        .tablet {
          display: none;
        }

        @include break(tablet) {
          padding-right: 0;
          border-bottom: 2px solid $dark;
          padding-bottom: 40px;
          margin-bottom: 40px;

          .tablet {
            display: block;
          }

          h5,
          p {
            text-align: center;
            padding-bottom: 10px;
          }
        }
      }
    }

    h1 {
      padding-bottom: 60px;

      @include break(tablet) {
        text-align: left;
        padding-bottom: 20px;
      }
    }

    .btn {
      width: 90%;
      text-align: center;
      justify-content: space-between;
      align-items: center;

      @include break(tablet) {
        display: none;
      }
    }

    .img {
      width: 100%;
      padding-bottom: 51%;
      position: relative;

      @include break(tablet) {
        width: 100vw;
        margin-left: -5vw;
        padding-bottom: 56%;
      }

      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .credit {
      position: absolute;
      top: 100%;
      left: 0;

      @include break(tablet) {
        left: 5%;
      }
    }
  }

  .sec2 {
    padding-bottom: 130px;

    @include break(tablet) {
      padding-bottom: 80px;
    }

    .container {
      display: flex;
      justify-content: space-between;

      @include break(tablet) {
        flex-direction: column;
      }
    }

    aside {
      @include break(tablet) {
        padding-top: 80px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    aside>div {
      margin-bottom: 50px;

      @include break(tablet) {
        width: 48%;
      }

      h6 {
        padding-bottom: 10px;
      }

      &:first-of-type {
        padding-top: 130px;
        border-top: 2px solid $dark;

        @include break(tablet) {
          border-top: none;
          padding-top: 0px;
        }
      }

      a {
        transition: all 0.3s;

        &.hover_blue:hover {
          color: $blue;
        }

        &.hover_green:hover {
          color: $green;
        }

        &.hover_mauve:hover,
        &.hover_pink:hover {
          color: $mauve;
        }

        &.hover_yellow:hover {
          color: $yellow;
        }

        &.hover_red:hover {
          color: $red;
        }
      }

      &.media {
        margin-bottom: 40px;

        @include break(tablet) {
          width: 100%;
        }
      }

      .media_wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        a {
          width: 69px;
          height: 37px;
          position: relative;
          border: 1px solid $dark;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 1;
          margin-bottom: 10px;

          img {
            width: 24px;
            height: auto;
            position: absolute;
            @include absolute-center(center);
          }

          &:hover img {
            filter: brightness(50);
          }
        }
      }

      &.listen {
        @include break(tablet) {
          width: 100%;
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        h6 {
          width: 100%;
        }

        a {
          border: 1px solid rgba($dark, 0.1);
          display: flex;
          align-items: center;
          width: 80%;
          padding: 10px;
          text-decoration: none;
          margin-bottom: 15px;
          transition: all 0.3s;

          @include break(tablet) {
            width: 48%;
          }

          img {
            width: 23px;
            height: auto;
            margin-right: 15px;
            transition: all 0.3s;
          }

          &:hover {
            border: 1px solid rgba($dark, 1);
            background-color: $dark;
            color: $white;

            img {
              filter: brightness(50);
            }
          }

          &.apple:hover {
            img {
              filter: none;
            }
          }
        }
      }

    }

    .content {
      padding-top: 130px;

      @include break(tablet) {
        padding-top: 20px;
      }

      span {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
      }
    }

    .video_wrap {
      padding-top: 80px;

      .slider_video-inner {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-right: calc(-1 * ((100vw - 1310px) / 2));

        @media only screen and (max-width: 1480px) {
          margin-right: -10%;
        }

        padding-bottom: 40px;

        &::-webkit-scrollbar {
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background-color: rgba($dark, 0.1);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: $dark;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: $dark;
        }

        &::-webkit-scrollbar-button:start:decrement {
          width: 100px;
          display: block;
          background-color: $bg;
        }

        &::-webkit-scrollbar-button:end:increment {
          width: calc(((100vw - 1310px) / 2) + 100px);
          display: block;
          background-color: $bg;

          @media only screen and (max-width: 1480px) {
            width: calc(10% + 100px);
          }
        }
      }

      .slide {
        width: 65%;
        padding-bottom: 36%;
        background-position: center;
        background-size: cover;
        position: relative;
        margin-right: 5%;
        flex-shrink: 0;
        cursor: pointer;

        .play_icon {
          position: absolute;
          @include absolute-center(center);
          width: 120px;
          height: 64px;
          cursor: pointer;
          border-width: 2px;

          svg {
            width: 120px;
            height: 64px;
          }

          @include break(small-screen) {
            width: 80px;
          }
        }
      }

      .slider_video-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -15px;
        position: relative;
        z-index: 9;
        pointer-events: none;

        .scroll_bar {
          background-color: rgba($dark, 0.1);
          width: 72%;
          height: 4px;
          position: relative;

          .handle {
            background-color: $dark;
            width: 20%;
            height: 4px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        svg {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }

    .modal_video .video {
      display: none;

      &.active {
        display: block;
      }
    }

    .review {
      padding-top: 80px;
      padding-bottom: 60px;
      border-bottom: 2px solid $dark;

      @include break(tablet) {
        padding-bottom: 120px;
      }

      .review_nav {
        border-top: 2px solid $dark;
        border-bottom: 2px solid $dark;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;

        .review_nav-sources {
          display: flex;

          div {
            @include font-clarendon;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            padding: 12px 18px;
            line-height: 1em;
            border-left: 2px solid $dark;
            border-right: 2px solid $dark;
            transition: all 0.4s;
            cursor: pointer;

            @include break(tablet) {
              display: none;
            }

            &.active,
            &:hover {
              background-color: $dark;
              color: $white;

              @include break(tablet) {
                display: block;
              }
            }
          }
        }

        .bullets_wrap {
          display: flex;
          align-items: center;

          .bullet {
            width: 17px;
            height: 17px;
            border: 1px solid $dark;
            display: block;
            border-radius: 50%;
            margin-left: 15px;
            cursor: pointer;
            transition: all 0.3s;

            &.active,
            &:hover {
              background-color: $dark;
            }
          }
        }
      }

      .review_content {
        margin: 0px 30px;
        // display: flex;
        position: relative;

        svg {
          flex-shrink: 0;
        }

        .review_tab {
          // Important
          font-weight: normal;
          // Important
          font-variation-settings: "wght" 700, "wdth" 100, "ital" 0;
          top: 0px;
          font-size: font-calc(36px);
          position: absolute;
          left: 60px;
          padding-right: 60px;
          padding-left: 60px;
          opacity: 0;

          @include break(tablet) {
            top: 60px;
            left: 0px;
            padding-right: 0px;
            padding-left: 0px;
          }

          &:first-of-type {
            opacity: 1;
          }
        }
      }
    }
  }

  .sec3 {
    padding-bottom: 130px;

    .bandeau_container {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .bandeau {
      margin-top: 80px;
      margin-bottom: 80px;

      .line {
        background-color: $white;
      }
    }

    .concert_wrap {
      border-top: 2px solid $white;

      .concert_item {
        border-bottom: 2px solid $white;
        padding-top: 35px;
        padding-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include break(tablet) {
          flex-direction: column;
          align-items: flex-start;
        }

        .concert_item-info {
          &.text-strike {
            text-decoration: line-through;
          }

          h3 {
            text-transform: uppercase;
            font-size: font-calc(36px);
            // Important
            font-weight: normal;
            // Important
            font-variation-settings: "wght" 200, "wdth" 100, "ital" 0;

            strong {
              font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
            }
          }

          p {
            @include font-clarendon-bold;
          }
        }
      }

      .concert_item-link {
        @include break(tablet) {
          width: 100%;
        }
      }

      .btn {
        width: 255px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: font-calc(50px);
        font-weight: normal;
        font-variation-settings: "wght" 200, "wdth" 40, "ital" 0;

        @include break(tablet) {
          width: 100%;
          height: 60px;
          justify-content: center;
          font-size: font-calc(35px);
          margin-top: 20px;
          margin-bottom: 20px;
          color: $white;
        }

        svg {
          width: 60px;

          @include break(tablet) {
            width: 30px;
          }

          g {
            transition: all 0.4s;
            stroke: $dark;

            @include break(tablet) {
              stroke: $white;
            }
          }
        }

        &.disabled-link {
          pointer-events: none;
          cursor: default;
        }

        &-cancel {
          font-size: font-calc(35px);
          line-height: 1.2em;
          justify-content: center;
          align-items: center;
          border: 4px solid $white;
          color: $white;
          background-color: transparent;
          flex-direction: column;

          @include break(tablet) {
            font-size: font-calc(25px);
          }
        }

        &:hover {
          font-variation-settings: "wght" 205, "wdth" 50, "ital" 0 !important;
          color: $white;

          svg g {
            stroke: $white;
          }
        }
      }
    }
  }

  .sec4 {
    cursor: none;

    .cursor {
      pointer-events: none;
      display: none;
      width: 140px;
      height: 70px;
      border-radius: 100%;
      overflow: hidden;
      position: fixed;
      transform: translate(-50%, -50%);
      background-color: $white;
      color: $dark;
      overflow: hidden;
      white-space: nowrap;
      z-index: 1;

      .wrap_cursor {
        display: inline-flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        transform-style: preserve-3d;

        span {
          @include font-bold;
          font-size: 1em;
          text-transform: uppercase;
          margin-right: 20px;
        }
      }
    }

    a {
      cursor: none;
      width: 100%;
      display: flex;
      text-decoration: none;
      justify-content: space-between;

      .nxt-left {
        padding-top: 80px;
        padding-bottom: 80px;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        padding-left: calc((100% - 1310px)/2);

        @include break(container) {
          padding-left: 5%;
        }

        @include break(tablet) {
          width: 100%;
          padding-right: 5%;
        }

        .top {
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;

          p {
            flex-shrink: 0;
            margin-right: 15px;
          }
        }

        h2 {
          font-size: font-calc(100px);
          text-transform: uppercase;
          font-weight: normal;
          font-variation-settings: "wght" 440, "wdth" 88, "ital" 0;
        }

        .line {
          background-color: $dark;
          height: 2px;
          width: 100%;
          flex-shrink: 1;
        }
      }

      .nxt-right {
        width: 50%;
        padding-bottom: 36%;
        position: relative;

        @include break(tablet) {
          display: none;
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &.bg_dark {
      a .nxt-left .line {
        background-color: $white;
      }
    }
  }

  .sec5 {
    background-color: $black;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      font-size: font-calc(60px);
      font-variation-settings: "wght" 440, "wdth" 140, "ital" 0;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;

      @include break(tablet) {
        flex-direction: column;
      }

      .btn_round {
        width: 188px;
        height: 100px;
        margin-left: 50px;

        @include break(tablet) {
          margin-left: 0px;
          transform: scale(0.5);
        }

        svg {
          width: 188px;
          height: 100px;
        }

        &:hover {
          g {
            stroke: $dark;
          }

          path {
            fill: none;
          }
        }
      }
    }
  }

}
