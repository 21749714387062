.oxo-view.template-single-concours {
  margin-top: 40px;
  @include break(small-screen) {
    margin-top: 100px;
  }
  .sec1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 76px);
    @include break(tablet) {
      flex-direction: column-reverse;
    }
    .img {
      width: 50%;
      top: 0;
      position: relative;
      bottom: 0;
      @include break(tablet) {
        width: 100%;
        padding-bottom: 75%;
      }

      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .img_thanks {
        left:0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        opacity: 0
      }
    }
    .content {
      width: 50%;
      padding: 80px 120px;
      @include break(small-screen) {
        padding: 40px 60px;
      }
      @include break(tablet) {
        width: 100%;
        padding: 60px 5%;
      }

      .content-wrap {
        position: relative;
        border-bottom: 2px solid $dark;
      }

      .focus {
        width: 100%;
        border-top: 2px solid $dark;
        border-bottom: 2px solid $dark;
        margin-bottom: 40px;
        span {
          display: inline-block;
          background-color: $dark;
          color: $white;
          text-transform: uppercase;
          padding: 0.5em 1.2em 0.35em 1.2em;
          font-size: font-calc(20px);
          letter-spacing: 0.25em;
          font-weight: normal;
          @include font-clarendon;
        }
      }

      h1 {
        @include font-base;
        font-size: cacl(90px);
        text-transform: uppercase;
        font-variation-settings: "wght" 440, "wdth" 40, "ital" 0;
        padding-bottom: 20px;
      }
      .btn {
        margin-top: 80px;
        margin-bottom: 20px;
        svg {
          width: 45px;
          height: auto;
          margin-left: 10px;
        }
        &:before {
          background-color: $white;
        }
        &:hover {
          color: $dark;
          svg path{
            stroke: $dark;
          }
        }
      }

      .form_wrap {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        opacity: 0;
      }
    }

  }

  .form_wrap {
    position: relative;
    width: 100%;
    padding-bottom: 110px;

    input, select {
      margin: 0;
      background-color: rgba($white, 0);
      border: 2px solid $dark;
      outline: none!important;
      border-radius: 0;
      color: $dark;
      font-size: 16px;
      padding: 12px 15px;
      margin-top: 10px;
      width: 100%;
      appearance: none;
      transition: 0.4s;

      &:focus {
        background-color: rgba($white, 1);
      }

      &[type='checkbox'] {
        display: inline-block;
        width: auto;
        border: 2px solid $dark;
        outline: none!important;
        border-radius: 0;
        background-color: $white;
        appearance: none;
        width: 15px;
        height: 15px;
        padding: 0;
        margin-right: 5px;
        margin-bottom: -2px;

        &:checked {
          background-color: $dark;
        }
      }
    }

    select {
      background-image: url("../img/select_arrow.png");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 20px);
      background-position-y: 15px;
      background-size: 20px;
    }

    input[type='submit'], button[type='submit'] {
      appearance: none;
      @include font-base;
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      background-color: $dark;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      padding: 14px 25px;
      width: 210px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      border: 2px solid $dark;
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }

    .wpcf7-radio .wpcf7-list-item {
      margin: 0;
      display: block;
      label {
        display: block;
        border: 2px solid $dark;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        margin-bottom: 20px;
        background-color: transparent;
        transition: all 0.4s;

        input {
          width: 40px;
          height: 20px;
          border-radius: 100%;
          border: 2px solid #232323;
          margin-right: 20px;
          margin-top: 0;
          padding: 9px;
          background-color: $bg;
          transition: all 0.4s;
        }

        span {
          @include font-bold;
          font-size: font-calc(20px);
        }

        &:hover {
          color: $white;
          input {
            background-color: $white;
          }
        }
      }
    }

    &.blue .wpcf7-radio .wpcf7-list-item label:hover, &.blue .wpcf7-radio .wpcf7-list-item input:checked {
      background-color: $blue;
    }
    &.green .wpcf7-radio .wpcf7-list-item label:hover, &.green .wpcf7-radio .wpcf7-list-item input:checked {
      background-color: $green;
    }
    &.mauve .wpcf7-radio .wpcf7-list-item label:hover, &.mauve .wpcf7-radio .wpcf7-list-item input:checked {
      background-color: $mauve;
    }
    &.yellow .wpcf7-radio .wpcf7-list-item label:hover, &.yellow .wpcf7-radio .wpcf7-list-item input:checked {
      background-color: $yellow;
    }
    &.red .wpcf7-radio .wpcf7-list-item label:hover, &.red  .wpcf7-radio .wpcf7-list-item input:checked {
      background-color: $red;
    }

    button[type='submit'] {
      width: auto;
      outline: none!important;
    }

    .login-submit {
      text-align: right;
      @include break(tablet) {
        text-align: left;
      }
    }

    .step {
      position: absolute;
      width: 100%;
      top:40px;
      left: 0;
      right: 0;
      bottom:140px;
      opacity: 0;
      display: none;

      h2 {
        font-size: font-calc(55px);
        @include font-bold;
        line-height: 1em;
        padding-bottom: 40px;
      }

       .txt-info {
         padding-bottom: 60px;
       }
    }

    .number_step {
      font-size: calc(14px);
      text-transform: uppercase;
    }

    .nav_form {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom:0;
      left: 0;
      right: 0;
      .btn_round {
        width: 96px;
        height: 56px;
        cursor: pointer;

        svg {
          height: auto;
          width: 40px;
        }
        &:hover svg path{
          fill: transparent;
          stroke: $white;
        }

      }

      input[type='submit'] {
        display: none;
      }
      .btn {
        margin-top: 0px;
        margin-bottom: 0px;
        &.not-active {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }

  .wpcf7 form.sent .wpcf7-response-output {
    border: none;
    z-index: 9;
    position: absolute;
    background-color: $bg;
    top: -45px;
    left: -15px;
    right: -15px;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }
  .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    border: none;
    position: absolute;
    bottom: 90px;
    left: 0;
    right: 0;
    padding: 0;
    line-height: 1em;
    font-size: 14px;
    color:$red;
  }
  .wpcf7-not-valid-tip {
    color: $red;
  }

  .step_thanks {
    position: absolute;
    background-color: $bg;
    top:-15px;
    left: -15px;
    right: -15px;
    bottom:-15px;
    opacity: 0;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    @include break(tablet) {
      left: 0;
      right: 0;
    }
    h2 {
      font-size: font-calc(55px);
      @include font-bold;
      line-height: 1em;
      padding-bottom: 40px;
    }
    .txt-info {
      padding-bottom: 60px;
    }
  }
}
