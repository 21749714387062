
#cookie-notice .cookie-notice-container {
  @include font-txt;
  padding: 25px 30px!important;
}

#cookie-notice .cn-button {
  margin: 0 0 0 14px!important;
}

a#cn-accept-cookie {
  font-size: 14px;
  padding: 8px 14px 9px;
  background: #2346dc;
  text-decoration: none;
  &:hover {
    background: #a94dfe;
  }
}

.cn-text-container {
  margin: 0!important;
  @include break(small-screen) {
    margin: 0 0 14px 0 ​!important;
  }
}


span#cn-notice-text  {
  @include break(small-screen) {
    padding-bottom: 20px!important;
  }
}

a#cn-close-notice {
  @include break(small-screen) {
    display: none;
  }
}

.cky-consent-container .cky-consent-bar {
  box-shadow: 0 0px 0px 0!important;
}
