header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100%);
  z-index: 998;
  background-color: $bg;
  border-bottom: 1px solid $dark;
  @include break(tablet) {
    width: 100%;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

  .logo {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100px;
    height: auto;
    @include break(small-screen) {
      width: 80px;
    }
  }
  .nav_mobile {
      display: none;
      height: 100vh;
      overflow: hidden;
      flex-direction: column;
      position: absolute;
      top:calc(100% + 1px);
      left: 0;
      right: 0;
      background-color: $bg;
      ul {
        padding-top: 40px;
        display: flex;
        flex-direction: column;

        li {
          padding-left: 30px;
          padding-right: 30px;
        }

        a {
          text-decoration: none;
          font-size: font-calc(32px);
          font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
          padding-top: 20px;
          padding-bottom: 20px;
          display: block;
        }
      }

      .logout {
        padding-left: 30px;
        padding-right: 30px;
        display: block;
        margin-top: 60px;
        text-transform: uppercase;
        @include font-medium;
        font-size: font-calc(16px);
      }
    }

  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    ul {
      display: flex;
      flex-direction: row;
    }

    li {
      padding-left: 30px;
      padding-right: 30px;
      &.current-menu-item a{
        font-variation-settings: "wght" 400, "wdth" 100, "ital" 60;
        pointer-events: none;
      }
    }

    a {
      color: $dark;
      text-decoration: none;
      font-size: font-calc(20px);
      font-variation-settings: "wght" 400, "wdth" 100, "ital" 0;
      text-align: center;
      transition: all 0.4s;

      &:hover {
        font-variation-settings: "wght" 420, "wdth" 140, "ital" 0;
      }

      &.current-menu-item {
        font-variation-settings: "wght" 400, "wdth" 100, "ital" 50;
        pointer-events: none;
      }
    }

  }
  .nav_desktop {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include break(small-screen) {
      display: none!important;
    }
    >div {
      width: 100%;
      flex-shrink: 1;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .login {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .line {
      height: 40px;
      display: inline-block;
      width: 2px;
      background-color: $dark;
      margin-left: 30px;
      margin-right: 60px;
    }

    // .login_icon {
    //   width: 80px;
    //   padding-top: 8px;
    // }

    .login_popUp {
      pointer-events: none;
      opacity: 0;
      position: absolute;
      top: 80px;
      right: -60px;
      transition: all 0.4s;
      .txt {
        width: 80%;
        @include absolute-center(center);
        line-height: 1em;
        font-size: font-calc(20px);
      }
    }

    &:hover .login_popUp {
      opacity: 1;
    }

    a:nth-of-type(2) {
      margin-left:30px;
    }
  }

  .nav_btn_mobile {
    display: none;
    flex-direction: row;
    align-items: center;
    position: relative;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;
    @include break(small-screen) {
      display: flex;
    }
    .login_link {
      width: 60px;
      height: 32px;
    }
    .burger_wrapper {
      display: flex;
      align-items: center;
    }
    .burger {
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      div {
        background-color: $dark;
        width: 100%;
        height: 3px;
        border-radius: 100px;
        transition: all 0.3s;
        &.init:nth-of-type(2){
          transition: all 0.3s 0.1s;
        }
        &.init:nth-of-type(3){
          transition: all 0.3s 0.2s;
        }

        &.close {
          position: absolute;
          opacity: 0;
          transition: all 0.3s;
          &.close1 {
            transform: rotate(45deg) scale(0.1);
            top: 11px;
            left: 0px;
          }
          &.close2 {
            transform: rotate(-45deg) scale(0.1);
            top: 11px;
            left: 0px;
          }
        }

      }
    }
    .txt {
      font-variation-settings: "wght" 400, "wdth" 100, "ital" 0;
      font-size: 16px;
      padding-left: 15px;
    }

    &.open {
      .burger div.init{
        transform:translateX(-100%);
      }
      .burger div.close {
        opacity: 1;
        &.close1 {
          transition: all 0.3s 0.3s;
          transform: rotate(45deg) scale(1);
        }
        &.close2 {
          transition: all 0.3s 0.4s;
          transform: rotate(-45deg) scale(1);
        }
      }
    }

  }

  .login_link {
    svg {
      width: 100%;
      height: 100%;
          padding-left: 2px;
    }
  }
}

.page-template-template-billetterie_landing .menu-item-558 {
  display: none;
}
