.template-single-concert {
  padding-bottom: 120px;
  padding-top: 80px;
  @include break(mobile) {
    padding-bottom: 80px;
    padding-top: 0px;
  }
  @include break(small-screen) {
    margin-top: 40px;
  }

  .art_link {
    text-transform: uppercase;
    font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
    letter-spacing: 0.75px;
    font-size: font-calc(15px);
    transition: all 0.4s;
    &:hover {
      font-variation-settings: "wght" 520, "wdth" 110, "ital" 20;
    }
    svg {
      margin-right: 10px;
      width: 24px;
    }
  }

  h1{
    margin-top: 0px;
    margin-bottom: 60px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 2px solid $dark;
    border-bottom: 2px solid $dark;
    display: block;
    max-width: 100%;
    font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
    @include break(mobile) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .sec2 {
    background-color: $white;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
    @include break(mobile) {
      margin-left: -5%;
      margin-right: -5%;
      padding-top: 40px;
      padding-bottom: 60px;
    }

    h6 {
      margin-bottom: 10px;
    }

    .info {
      border-top: 2px solid $dark;
      border-bottom: 2px solid $dark;
      padding-top: 30px;
      margin-top: 20px;
      padding-bottom: 30px;
      margin-bottom: 130px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
        margin-bottom: 80px;
      }

      h3 {
        @include font-bold;
        font-size: font-calc(32px);
      }

      .info_date {
        @include font-bold;
        font-size: font-calc(18px);
        text-transform: uppercase;
      }

      .info_salle {
        margin-top: 20px;
        @include font-regular;
        font-size: font-calc(18px);
      }
      .txt {
        margin-top: 20px;
      }

      .right {
        position: relative;
        flex-shrink: 0;
        width: 260px;
        height: 190px;
        @include break(mobile) {
          display: none;
        }

        picture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }


    iframe {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 20px);
    }

    .btn {
      width: 200px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.5em;
      font-weight: normal;
      font-variation-settings: "wght" 200, "wdth" 40, "ital" 0;
      @include break(tablet) {
        width: 100%;
        height: 60px;
        justify-content: center;
        font-size: font-calc(35px);
        margin-top: 20px;
        margin-bottom: 20px;
      }

      svg {
        width: 40px;
        @include break(tablet) {
          width: 30px;
        }
        g {
          stroke: $white;
        }
      }

      &.disabled-link {
        pointer-events: none;
        cursor: default;
      }

      &:hover {
        font-variation-settings: "wght" 205, "wdth" 50, "ital" 0!important;
      }

      &-cancel {
        font-size: font-calc(35px);
        line-height: 1.2em;
        justify-content: center;
        align-items: center;
        border: 4px solid $dark;
        flex-direction: column;
        // pointer-events: none;
        @include break(tablet) {
          font-size: font-calc(25px);
        }
      }
    }
  }
}
