.oxo-view.template-login {
  padding-top: 120px;
  padding-bottom: 120px;
  @include break(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 60px;
    border-bottom: 4px solid $dark;
    margin-bottom: 60px;
    @include break(tablet) {
      display: block;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    h1 {
      width: 45%;
      @include font-base;
      font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
      font-size: font-calc(60px);
      text-align: left;
      @include break(tablet) {
        width: 100%;
      }
    }
    p {
      @include font-medium;
      width: 45%;
      text-align: right;
      @include break(tablet) {
        width: 100%;
        text-align: left;
      }
    }
  }

  .login-form {
    label {
      @include font-medium;
      font-size: 12px;
      letter-spacing: 0;
      text-transform: uppercase;
      display: block;
      padding-top: 40px;
      width: 100%;
    }
    input {
      margin: 0;
      background-color: rgba($white, 0);
      border: 2px solid $dark;
      outline: none!important;
      border-radius: 0;
      color: $dark;
      font-size: 16px;
      padding: 12px 15px;
      margin-top: 10px;
      width: 100%;
      appearance: none;
      transition: 0.4s;

      &:focus {
        background-color: rgba($white, 1);
      }

      &#rememberme {
        display: inline-block;
        width: auto;
        border: 2px solid $dark;
        outline: none!important;
        border-radius: 0;
        background-color: $white;
        appearance: none;
        width: 15px;
        height: 15px;
        padding: 0;
        margin-right: 5px;
        margin-bottom: -2px;

        &:checked {
          background-color: $dark;
        }
      }
    }

    input[type='submit'], button[type='submit'] {
      appearance: none;
      @include font-base;
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      background-color: $dark;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      padding: 14px 25px;
      width: 210px;
      cursor: pointer;
      position: relative;
      margin-top: 30px;
      transition: all 0.4s;
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }
    button[type='submit'] {
      width: auto;
      outline: none!important;
      border: 2px solid $dark;
    }

    .login-submit {
      text-align: right;
      @include break(tablet) {
        text-align: left;
      }
    }
  }

}
