.oxo-view.template-account {
  min-height: calc(100vh - 380px);
  padding-top: 120px;

  @include break(tablet) {
    padding-top: 80px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 4px solid $dark;
    border-bottom: 4px solid $dark;
    @include break(tablet) {
      display: block;
    }
    h1 {
      width: 30%;
      max-width: 325px;
      margin-bottom: 0px;
      margin-right: 5%;
      border-right: 4px solid $dark;
      padding-top: 20px;
      padding-bottom: 20px;
      @include break(tablet) {
        border-right: none;
        width: 100%;
        max-width: 100%;
      }
    }
    p {
      @include font-regular;
      width: 70%;
      flex-grow: 1;
      @include break(tablet) {
        width: 100%;
        text-align: left;
      }
    }
  }

  h1 {
    @include font-base;
    font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
    font-size: font-calc(60px);
    text-align: left;
    margin-bottom: 40px;
    @include break(tablet) {
      width: 100%;
    }
  }

  .desc {
    @include font-medium;
    margin-bottom: 0px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 120px;
    @include break(tablet) {
      padding-top: 40px;
      padding-bottom: 80px;
      display: block;

    }
    aside {
      width: 30%;
      max-width: 325px;
      margin-bottom: 0px;
      margin-right: 5%;
      border-right: 4px solid $dark;
      padding-right: 50px;
      position: relative;
      @include break(tablet) {
        width: 100%;
        max-width: 100%;
        border-right: none;
        padding-right: 0px;
        margin-right: 0%;
      }

      .sticky {
        position: sticky;
        top: 120px;
        @include break(tablet) {
          position: static;
          display: flex;
          top: 0;
          width: 100%;
          justify-content: space-between;
        }
      }

      .option {
        background-color: $light-grey;
        color: $dark;
        padding: 15px;
        font-size: font-calc(20px);
        text-transform: uppercase;
        @include font-medium;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.4s;
        @include break(tablet) {
          flex-grow: 0;
          padding: 10px;
          width: 150px;
          font-size: font-calc(18px);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        &.active, &:hover {
          background-color: $dark;
          color: $white;
        }
        &.active {
          pointer-events: none;
        }
      }

      a {
        display: block;
        margin-top: 80px;
        text-transform: uppercase;
        @include font-medium;
        font-size: font-calc(16px);
        @include break(tablet) {
          display: none;
        }
      }
    }
  }

  .form_wrap {
    width: 70%;
    flex-grow: 1;
    position: relative;
    @include break(tablet) {
      width: 100%;
      margin-top: 40px;
    }
  }

  .form2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    display: none;
  }


  form {
    position: relative;
    width: 100%;
    padding-bottom: 110px;

    label {
      @include font-medium;
      font-size: 12px;
      letter-spacing: 0;
      text-transform: uppercase;
      display: block;
      padding-top: 40px;
      width: 100%;
    }
    .two-col-label {
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
       flex-direction: column;
      }
      >div {
        width: 45%;
        display: flex;
        @include break(tablet) {
          width: 100%;
        }

        svg {
          margin-left: 10px;
        }

        &.right {
          @include font-txt;
          text-transform: none;
          justify-content: flex-end;
          transition: all 0.3s;
          @include break(tablet) {
            justify-content: flex-start;
          }
        }
      }
    }
    .two_col {
      display: flex;
      justify-content: space-between;
      @include break(tablet) {
       flex-direction: column;
      }
      >div {
        width: 45%;
        @include break(tablet) {
          width: 100%;
        }
      }
    }

      input, select {
        margin: 0;
        background-color: rgba($white, 0);
        border: 2px solid $dark;
        outline: none!important;
        border-radius: 0;
        color: $dark;
        font-size: 16px;
        padding: 12px 15px;
        margin-top: 10px;
        width: 100%;
        appearance: none;
        transition: 0.4s;

        &:focus {
          background-color: rgba($white, 1);
        }

        &[type='checkbox'] {
          display: inline-block;
          width: auto;
          border: 2px solid $dark;
          outline: none!important;
          border-radius: 0;
          background-color: $bg;
          appearance: none;
          width: 15px;
          height: 15px;
          padding: 0;
          margin-right: 5px;
          margin-bottom: -2px;

          &:checked {
            background-color: $dark;
          }
        }
      }
      .date_wrap {
        display: flex;
        padding-top: 10px;
      }
      .date_j, .date_m {
        width: 80px;
        margin-right: 20px;
      }
      .date_a {
        width: 120px;
      }
      select {
        background-image: url("../img/select_arrow.png");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 15px;
        background-size: 20px;
      }



    input[type='submit'] {
      appearance: none;
      @include font-base;
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      background-color: $dark;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 16px;
      padding: 14px 25px;
      width: 210px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s;
      border: 2px solid $dark;
      margin-top: 80px;
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }

    .acf-field-614bdb0f17570 label, .acf-field-614b6e5096bc2 label {
      padding-top: 0;
    }

    button[type='submit'] {
      width: auto;
      outline: none!important;
    }

    .login-submit {
      text-align: right;
      @include break(tablet) {
        text-align: left;
      }
    }

    .errorMessage {
      color: $red;
      font-size: 14px;
      margin-top: 5px;
      opacity: 0;
    }

    .acf-fields>.acf-field {
      padding: 0;
    }
    .select2-container--default {
      .select2-selection--multiple {
        background-color: rgba($white, 0);
        border: 2px solid $dark;
        border-radius: 0;
        font-size: 16px;
        padding: 5px 15px;
        height: 48px;
        transition: all 0.4s;
        display: flex;
        align-items: center;
        .select2-selection__choice {
          background-color: $dark;
          border: none;
          color: $white;
          padding: 6px 9px;
          margin-top: 1px;
        }
        .select2-search__field {
          height: 33px;
        }
      }
      &.select2-container--focus .select2-selection--multiple {
        background-color: rgba($white, 1);
      }
    }

    .form_sec {
      position: absolute;
      width: 100%;
      top:0;
      left: 0;
      right: 0;
      opacity: 0;
      display: none;

      &:first-of-type {
        position: relative;
        opacity: 1;
        display: block;
      }
    }

  }
  .btn {
    svg {
      width: 45px;
      height: auto;
      margin-left: 10px;
    }
    &:before {
      background-color: $white;
    }
    &:hover {
      color: $dark;
      svg path{
        stroke: $dark;
      }
    }
  }

  .sec3 {
    background-color: $blue;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      font-size: font-calc(60px);
      font-variation-settings: "wght" 440, "wdth" 140, "ital" 0;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      @include break(tablet) {
        flex-direction: column;
      }
      .btn_round {
        width: 188px;
        height: 100px;
        margin-left: 50px;
        @include break(tablet) {
          margin-left: 0px;
          transform: scale(0.5);
        }
        svg {
          width: 188px;
          height: 100px;
        }

        &:hover {
          g {
            stroke: $dark;
          }
          path {
            fill: none;
          }
        }
      }
    }
  }
}
