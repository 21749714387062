.oxo-view.template-archive-concours {

  @include break(small-screen) {
    margin-top: 100px;
  }
  
  .sec1 {
    border-bottom: 4px solid $dark;
  }
  .sec2 {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include break(tablet) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include break(mobile) {
      flex-direction: column;
    }

    .concours_thumb {
      width: 48%;
      max-width: 715px;
      flex-shrink: 0;
      padding-bottom: 30px;
      border-bottom: 4px solid $dark;
      margin-bottom: 80px;
      text-decoration: none;
      cursor: none;
      @include break(mobile) {
        width: 100%;
      }
      .cursor {
        pointer-events: none;
        display: none;
        width: 140px;
        height: 70px;
        border-radius: 100%;
        overflow: hidden;
        position: fixed;
        transform: translate(-50%, -50%);
        background-color: $dark;
        color: $white;
        overflow: hidden;
        white-space: nowrap;
        .wrap_cursor {
          display: inline-flex;
          width: 100%;
          height: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
          transform-style: preserve-3d;

          span {
            @include font-bold;
            font-size: 1em;
            text-transform: uppercase;
            margin-right: 20px;
          }
        }
      }
      &.hover_blue .cursor {
        background-color: $blue;
      }
      &.hover_green .cursor {
        background-color: $green;
      }
      &.hover_mauve .cursor, .hover_pink .cursor {
        background-color: $mauve;
      }
      &.hover_yellow .cursor {
        background-color: $yellow;
      }
      &.hover_red .cursor {
        background-color: $red;
      }

      .img {
        width: 100%;
        padding-bottom: 66%;
        background-position: center;
        background-size: cover;
      }
      h3 {
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: font-calc(36px);
        line-height: 1em;
        @include font-base;
        font-variation-settings: "wght" 600, "wdth" 80, "ital" 0;
        transition: all 0.4s;
      }

      &:hover {
        h3 {
          font-variation-settings: "wght" 600, "wdth" 80, "ital" 100;
        }
        &.hover_blue h3 {
          color: $blue;
        }
        &.hover_green h3 {
          color: $green;
        }
        &.hover_mauve h3, .hover_pink h3 {
          color: $mauve;
        }
        &.hover_yellow h3 {
          color: $yellow;
        }
        &.hover_red h3 {
          color: $red;
        }
      }
    }
  }

}
