footer {
  padding-top: 120px;
  padding-bottom: 50px;
  @include break(tablet) {
    padding-top: 80px;
  }
  .container >div {
    display: flex;
    justify-content: space-between;
    @include break(tablet) {
      flex-direction: column;
    }
  }

  .top {
    padding-bottom: 50px;
    border-bottom: 1px solid $white;
    align-items: flex-start;
    width: 100%;
    .left {
      width: 650px;
      @include break(tablet) {
        width: 100%;
      }
    }
    p {
      max-width: 380px;
      font-size: font-calc(24px);
      @include font-regular;
      padding-bottom: 20px;
      @include break(tablet) {
        width: 90%;
        font-size: 16px;
      }
    }
    input {
      background-color: $dark;
      border: 1px solid $white;
      outline: none!important;
      color: $white;
      font-size: 16px;
      padding: 16px 15px;
      width: 280px;
      @include break(tablet) {
        padding: 14px;
        font-size: 14px;
        width: calc(100% - 102px);
        max-width: 210px;
      }
    }
    .btn {
      border: 1px solid $white;
      padding: 13px 24px;
      @include break(tablet) {
        padding: 12px;
        font-size: 14px;
      }
    }
    .contest_link {
      display: block;
      padding-top: 30px;
      color: $white;
      @include font-medium;
    }
    .right {
      display: flex;
      flex-direction: row;
      @include break(tablet) {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }
      .btn_round {
        margin-left: 30px;
        @include break(tablet) {
          margin-right: 15px;
          margin-left: 15px;
        }
      }
    }
  }

  .bottom {
    align-items: center;
    padding-top: 25px;
    .logo {
      width: 100px;
      @include break(tablet) {
        display: none;
      }
    }
    p {
      font-size: 14px;
    }
  }
}
