.quatre-cent-quatre {

  .sec1 {
    border-bottom: 4px solid $dark;
  }
  .bandeau {
    font-size: font-calc(160px);
    @include break(tablet) {
      font-size: 6vw;
      padding-top: 6vw;
      padding-bottom: 6vw;
    }
  }
  .sec2 {
    padding-top: 80px;
    padding-bottom: 80px;
    border-top: 4px solid $dark;
    h2 {
      @include font-regular;
      // font-variation-settings: "wght" 440, "wdth" 120, "ital" 0;
      // text-transform: uppercase;
      // font-size: font-calc(85px);
      font-size: font-calc(65px);
      @include font-bold;
      letter-spacing: 0;
      line-height: 1em;
      padding-bottom: 10px;
      @include break(tablet) {
        text-align: left;
        font-size: font-calc(70px);
      }
    }
    .content {
      width: 100%;
    }
    .nxt {
      padding-top: 80px;
      width: 100%;
      .focus {
        width: 100%;
        border-top: 2px solid $dark;
        border-bottom: 2px solid $dark;
        margin-bottom: 40px;
        span {
          display: inline-block;
          background-color: $dark;
          color: $white;
          text-transform: uppercase;
          padding: 0.5em 1.2em 0.35em 1.2em;
          font-size: font-calc(20px);
          letter-spacing: 0.25em;
          font-weight: normal;
          @include font-clarendon;
        }
      }
      .concert_inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      .date {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-right: 65px;
        margin-right: 65px;
        border-right: 2px solid $dark;
        width: 290px;
        @include break(small-screen) {
          padding-right: 30px;
          margin-right: 40px;
          width: 250px;
        }
        @include break(tablet) {
          padding-right: 15px;
          margin-right: 20px;
          margin-top: 20px;
          margin-bottom: 0px;
        }
        @include break(mobile) {
          width: 95px;
        }
      }

      .concert_info {
        width: 100%;
        flex-shrink: 1;
        padding-top: 50px;
        padding-bottom: 50px;
        z-index: 0;
        text-decoration: none;
        margin-right: 60px;
        @include break(tablet) {
          padding-top: 16px;
          padding-bottom: 0px;
          margin-right: 0px;
        }

        h3 {
          position: relative;
          z-index: 1;
          font-weight: normal;
          font-variation-settings: "wght" 440, "wdth" 48, "ital" 0;
          text-transform: uppercase;
          font-size: font-calc(90px);
          line-height: 1em;
          background-color: $dark;
          background-image: linear-gradient(-90deg, $dark 0% 50%, $dark);
          background-size: 220% 100%;
          background-position: top right;
          color: transparent;
          background-clip: text;
          transition: all 0.6s;
          @include break(mobile) {
            font-size: font-calc(40px);
          }
        }

        p {
          position: relative;
          z-index: 1;
          background-color: $dark;
          color: $white;
          display: inline-block;
          line-height: 1em;
          vertical-align: middle;
          text-transform: uppercase;
          padding: 0.5em 0.3em 0.3em 0.3em;
          @include font-clarendon-bold;
          @include break(mobile) {
            font-size: 10px;
            @include font-clarendon;
            margin-right: 20px;
            letter-spacing: 3px;
            display: inline;
          }
        }

        &.hover_blue h3{
          background-image: linear-gradient(90deg, $blue 0% 50%, $dark 50%);
        }
        &.hover_green h3{
          background-image: linear-gradient(90deg, $green 0% 50%, $dark 50%);
        }
        &.hover_mauve h3, &.hover_pink h3{
          background-image: linear-gradient(90deg, $mauve 0% 50%, $dark 50%);
        }
        &.hover_yellow h3{
          background-image: linear-gradient(90deg, $yellow 0% 50%, $dark 50%);
        }
        &.hover_red h3{
          background-image: linear-gradient(90deg, $red 0% 50%, $dark 50%);
        }

        &:hover h3 {
          font-variation-settings: "wght" 445, "wdth" 53, "ital" 75;
          background-position: top left;
        }
      }

      .concert_item-link {
        .btn {
          width: 255px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: font-calc(50px);
          font-weight: normal;
          font-variation-settings: "wght" 200, "wdth" 40, "ital" 0;
          @include break(tablet) {
            width: 100%;
            height: 60px;
            justify-content: center;
            font-size: font-calc(35px);
            margin-top: 20px;
            margin-bottom: 20px;
          }

          svg {
            width: 60px;
            @include break(tablet) {
              width: 30px;
            }
            g {
              stroke: $white;
            }
          }

          &:hover {
            font-variation-settings: "wght" 205, "wdth" 50, "ital" 0!important;
          }

          &-cancel {
            font-size: font-calc(35px);
            line-height: 1.2em;
            justify-content: center;
            align-items: center;
            border: 4px solid $dark;
            flex-direction: column;
            // pointer-events: none;
            @include break(tablet) {
              font-size: font-calc(25px);
            }
          }
        }
      }
    }
  }

  .sec3 {
    background-color: $blue;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-decoration: none;
      font-weight: normal;
      text-align: center;
      font-size: font-calc(60px);
      font-variation-settings: "wght" 440, "wdth" 140, "ital" 0;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      @include break(tablet) {
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .btn_round {
        width: 188px;
        height: 100px;
        margin-left: 50px;
        @include break(tablet) {
          margin-left: 0px;
          transform: scale(0.5);
        }
        svg {
          width: 188px;
          height: 100px;
        }

        &:hover {
          g {
            stroke: $blue;
          }
          path {
            fill: none;
          }
        }
      }
    }
  }

}
