.toggle_concert {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  width: 120px;
  bottom: 0;
  cursor: pointer;
  background-color: $dark;
  box-sizing: content-box;

  @include break(tablet) {
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    -webkit-backface-visibility: hidden;
  }

  >div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .inner {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 120px;
      height: 500px;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @media only screen and (max-height: 775px) and (min-width: $break-tablet) {
        height: 420px;
      }

      @include break(tablet) {
        top: auto;
        width: 100%;
        height: 75px;
        width: 300px;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0%);
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: row;
      }
    }

    h2 {
      text-transform: uppercase;
      @include font-medium;
      font-size: font-calc(50px);
      width: 350%;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%) rotate(90deg);

      @media only screen and (max-height: 775px) and (min-width: $break-tablet) {
        font-size: font-calc(40px);
      }

      @include break(tablet) {
        transform: translate(-50%, -50%) rotate(0deg);
        font-size: font-calc(28px);
      }
    }

    &.open {
      color: $white;
      background-color: $dark;

      svg {
        width: 20px;
        transform: rotate(90deg);

        @include break(tablet) {
          transform: rotate(-180deg) scale(0.5);
        }
      }
    }

    &.close {
      opacity: 0;
      background-color: $white;
      color: $dark;

      svg {
        width: 20px;
        transform: rotate(-90deg);

        @include break(tablet) {
          transform: rotate(0deg) scale(0.5);
        }
      }
    }
  }
}

.template-concerts {
  // display: none;
  position: fixed;
  top: 0;
  left: 100vw;
  bottom: 0;
  width: 100vw;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 120px;

  @include break(tablet) {
    padding-left: 0px;
    padding-top: 75px;
    left: 0;
    top: 100%;
  }

  .sec1 {
    padding-top: 120px;
    padding-bottom: 70px;

    @include break(tablet) {
      padding-bottom: 0px;
      padding-top: 20px;
    }

    >.container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include break(tablet) {
        margin-bottom: 15px;
      }
    }

    h2 {
      font-variation-settings: "wght" 700, "wdth" 68, "ital" 0;
      text-transform: uppercase;
      font-size: font-calc(60px);
      margin-right: 40px;
      width: 100%;
      flex-shrink: 1;
      line-height: 1.2;

      @include break(tablet) {
        width: 80%;
        max-width: 300px;
        font-size: font-calc(40px);
        margin-bottom: 0px;
      }
    }

    .open_filter {
      display: none;
      background-color: $grey;
      padding: 15px 30px;
      font-size: 0.875em;
      align-items: center;
      @include font-medium;

      @include break(tablet) {
        display: flex;
      }

      svg {
        margin-right: 15px;
      }
    }

    .filter {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      @include break(tablet) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $dark;
        z-index: 9999;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: calc(75px + 5vw);
      }

      .filterTop {
        border-bottom: 1px solid $white;
        padding: 20px 5%;
        margin-bottom: 30px;
        display: none;

        @include break(tablet) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0;
        }

        svg {
          margin-right: 10px;
        }
      }

      .filterBottom {
        background-color: $white;
        padding: 15px 30px;
        text-transform: uppercase;
        text-align: center;
        color: $dark;
        position: absolute;
        left: 5%;
        right: 5%;
        bottom: 7vh;
        @include font-medium;
        display: none;
        z-index: 9999999;

        @include break(tablet) {
          display: block;
        }
      }

      .select {
        margin-left: 40px;
        position: relative;
        flex-shrink: 0;

        @include break(tablet) {
          margin-left: 0;
          margin-bottom: 30px;
          margin-left: 5%;
          margin-right: 5%;
        }

        &-parent {
          background-color: $grey;
          padding: 15px 30px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-size: 0.875em;
          border: 2px solid $dark;
          @include font-medium;
          transition: all 0.4s;
          cursor: pointer;

          @include break(tablet) {
            justify-content: space-between;
          }

          .nb_filter {
            padding-left: 5px;
            display: inline-block;
            opacity: 0;
          }

          img {
            margin-left: 10px;
            transition: all 0.4s;
            transform: rotate(180deg);

            @include break(tablet) {
              transform: rotate(90deg);
            }
          }

          &:hover {
            border: 2px solid $white;
          }

          &.active {
            border: 2px solid $white;
            background-color: $dark;

            img {
              transform: rotate(0deg);
            }
          }

          &.active--filter {
            border: 2px solid $white;

            .nb_filter {
              opacity: 1;
            }
          }
        }

        .dropdown {
          position: absolute;
          top: calc(100% - 10px);
          width: 260px;
          height: auto;
          border: 2px solid $white;
          background-color: $dark;
          left: 0;
          display: none;
          opacity: 0;
          overflow: hidden;
          z-index: 99;

          @include break(tablet) {
            border: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding-bottom: calc(75px + 5vw);
            background-color: $dark;
            z-index: 99999;
            width: 100%;
          }

          input {
            border-radius: 0;
            border: none;
            border-bottom: 2px solid $white;
            font-size: 1em;
            padding: 15px 20px;
            background-color: transparent;
            color: $white;
            width: 100%;

            @include break(tablet) {
              width: 90%;
              margin-left: 5%;
              margin-right: 5%;
              border: 2px solid $white;
            }

            &:focus {
              outline: none;
            }
          }
        }

        &.filter-artist .dropdown {
          left: auto;
          right: 0;
        }

        ul {
          list-style-type: none;
          padding: 30px 20px;
          overflow-x: hidden;
          overflow-y: scroll;
          margin: 0;
          height: 220px;

          @include break(tablet) {
            padding: 5%;
            height: calc(100vh - 240px);
            -webkit-overflow-scrolling: touch;
          }

          &::-webkit-scrollbar {
            width: 14px;

            @include break(tablet) {
              width: auto;
            }
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: $dark;
            border-left: 1px solid $white;

            @include break(tablet) {
              display: none
            }
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $white;

            @include break(tablet) {
              display: none
            }
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            transition: all 0.3s;
            background: $white;

            @include break(tablet) {
              display: none
            }
          }
        }

        li {
          cursor: pointer;
          transition: opacity 0.4s;
          margin-bottom: 20px;
          text-transform: capitalize;
          transition: all 0.3s;

          &:last-of-type {
            margin-bottom: 0;
          }

          &.not-active {
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }

          &:hover {
            font-variation-settings: "wght" 400, "wdth" 80, "ital" 30;
          }
        }

      }

      .reset {
        text-decoration: underline;
        text-align: right;
        margin-top: 5px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s;

        @include break(tablet) {
          margin-top: 20px;
          font-variation-settings: "wght" 400, "wdth" 60 "ital" 0;
          font-size: 22px;
          text-transform: uppercase;
        }

        &.active {
          opacity: 1;
          pointer-events: all;
          cursor: pointer;
        }
      }
    }
  }

  .sec2 {

    .container-month {
      display: grid;
      grid-template-columns: 10% 90%;

      @include break(tablet) {
        display: flex;
        flex-direction: column;
      }

      &.first {
        .month {
          border-top: 1px solid $white;

          @include break(tablet) {
            border-top: none;
          }
        }

        .wrapper-concert {
          .concert {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              margin-top: 0;
              border-top: 1px solid $white;

              @include break(tablet) {
                margin-top: -1px;
              }
            }
          }
        }
      }

      &.last {
        .month {
          margin-bottom: -1px;
        }

        .wrapper-concert {
          border-bottom: 0;

          .concert {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              border-top: 0;

              @include break(tablet) {
                border-top: 1px solid $white;
              }
            }
          }
        }
      }
    }

    .wrapper-concert {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      border-bottom: 1px solid $white;
      border-left: 1px solid $white;
      grid-gap: 1px;

      @include break(tablet) {
        grid-template-columns: repeat(2, 1fr);
        border: 0px solid;
      }

      @include break(mobile) {
        grid-template-columns: 1fr;
      }
    }

    .month {
      padding-top: 20px;
      padding-right: 15px;
      border-bottom: 1px solid $white;

      @include break(tablet) {
        border-bottom: none;
      }

      span {
        font-variation-settings: "wght" 400, "wdth" 0, "ital" 0;
        text-transform: uppercase;
        vertical-align: middle;
        display: inline-block;
        font-size: font-calc(154px);
        line-height: 100%;

        @include break(tablet) {
          font-size: font-calc(54px);
          font-variation-settings: "wght" 500, "wdth" 20, "ital" 0;
          padding-left: 0px;
          padding-bottom: 5px;
        }

        &.year {
          font-size: font-calc(264px);
          word-break: break-all;
          line-height: 75%;

          @include break(tablet) {
            font-size: font-calc(54px);
            font-variation-settings: "wght" 500, "wdth" 20, "ital" 0;
            padding-left: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }

    .concert {
      display: flex;
      align-items: center;
      padding: 30px;
      border: 1px solid;
      margin: -1px;
      position: relative;

      @include break(tablet) {
        border-top: 2px solid $white!important;
        border-left: 0px solid ;
        border-bottom: 0px solid ;
        padding-left: 0px!important;
        padding-top: 15px!important;
        padding-bottom: 15px!important;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-top: 0;

        @include break(tablet) {
          border-top: 1px solid $white;
        }
      }

      @include break(tablet) {
        padding: 10px 0px 20px 30px;
        border-right: 0;
      }

      .date_month {
        display: none;
      }
    }

    .concert_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 0;
      margin-right: auto;

      @include break(tablet) {
        flex-direction: row;
        align-items: center;
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include break(tablet) {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      @include break(mobile) {
        width: 95px;
      }

    }

    .date_l {
      order: 2;
      font-size: 23px;
      display: block;
      line-height: 50%;
      width: fit-content;

      @include break(tablet) {
        border-right: 2px solid white;
        padding-right: 10px;
        min-width: 65px;
      }

    }

    .date_day {
      @include font-medium;
      font-variation-settings: "wght" 600, "wdth" 35, "ital" 0 !important;
      font-size: 23px;
      letter-spacing: 0;
      @include break(tablet) {
        font-variation-settings: "wght" 600, "wdth" 16, "ital" 0 !important;
        font-size: 20px;
      }
    }

    .date_numb {
      @include font-medium;
      font-variation-settings: "wght" 500, "wdth" 50, "ital" 0;
      order: 1;
      font-size: 34px;
      padding-left: 0;
      &--uppercase {
        text-transform: uppercase;
        font-variation-settings: "wght" 500, "wdth" 20, "ital" 0;
        font-size: 34px;
        padding-left: 5px;

        @include break(tablet) {
          padding-left: 0px;
          display: block;
        }
      }

      @include break(tablet) {
        font-variation-settings: "wght" 600, "wdth" 100, "ital" 0;
      }
    }

    .concert_info {
      width: 100%;
      flex-shrink: 1;
      padding-top: 20px;
      padding-bottom: 16px;
      z-index: 0;
      text-decoration: none;
      line-height: 1rem;

      @include break(tablet) {
        padding: 4px 12px 0px 12px;
        margin-right: 0px;
      }

      h3 {
        display: block;
        position: relative;
        padding-bottom: 10px;
        z-index: 1;
        font-weight: normal;
        font-variation-settings: "wght" 440, "wdth" 48, "ital" 0;
        font-size: 24px;
        line-height: 1em;
        background-color: $white;
        background-image: linear-gradient(-90deg, $white 0% 50%, $white);
        background-size: 220% 100%;
        background-position: top right;
        color: transparent;
        background-clip: text;
        transition: all 0.6s;
      }

      p {
        position: relative;
        z-index: 1;
        color: $white;
        display: inline-block;
        line-height: 1.4em;
        vertical-align: middle;
        text-transform: uppercase;
        @include font-clarendon-bold;
        font-size: .8rem;
        letter-spacing: 1px;

        @include break(mobile) {
          font-size: 10px;
          @include font-clarendon;
          margin-right: 20px;
          letter-spacing: 3px;
          display: inline;
        }
      }

      &.hover_blue h3 {
        background-image: linear-gradient(90deg, $blue 0% 50%, $white 50%);
      }

      &.hover_green h3 {
        background-image: linear-gradient(90deg, $green 0% 50%, $white 50%);
      }

      &.hover_mauve h3,
      &.hover_pink h3 {
        background-image: linear-gradient(90deg, $mauve 0% 50%, $white 50%);
      }

      &.hover_yellow h3 {
        background-image: linear-gradient(90deg, $yellow 0% 50%, $white 50%);
      }

      &.hover_red h3 {
        background-image: linear-gradient(90deg, $red 0% 50%, $white 50%);
      }

      &:hover h3 {
        font-variation-settings: "wght" 440, "wdth" 48, "ital" 100;
        background-position: top left;
      }
    }

    .concert_item-link {
      .btn {
        width: fit-content;
        height: 36px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: normal;
        font-variation-settings: "wght" 200, "wdth" 74, "ital" 0;
        background-color: $white;
        border-radius: 2px;
        transition: all 0.4s;
        color: $dark;

        @include break(tablet) {
          height: 40px;
          width: fit-content;
          padding: 10px 6px 10px 10px;
          justify-content: center;
          margin-top: 0px;
          margin-bottom: 0px;
          color: $white;

          &.hover_blue {
            background-color: $blue;
          }

          &.hover_green {
            background-color: $green;
          }

          &.hover_mauve,
          &.hover_pink {
            background-color: $mauve;
          }

          &.hover_yellow {
            background-color: $yellow;
          }

          &.hover_red {
            background-color: $red;
          }
        }

        svg {
          width: 30px;

          @include break(tablet) {
            width: 24px;
            padding-left: 2px;
          }

          g {
            transition: all 0.4s;
            stroke: $dark;

            @include break(tablet) {
              stroke: $white;
            }
          }
        }

        &.disabled-link {
          pointer-events: none;
          cursor: default;
        }

        &-cancel {
          font-size: 18px;
          line-height: 1rem;
          justify-content: center;
          align-items: center;
          border: 1px solid $white;
          background-color: $dark;
          color: $white;
        }

        &:hover {
          font-variation-settings: "wght" 205, "wdth" 85, "ital" 0 !important;
          color: $white;

          svg g {
            stroke: $white;
          }
        }
      }
    }

    .more {
      margin-top: 80px;
      margin-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: font-calc(20px);
      font-variation-settings: "wght" 500, "wdth" 100, "ital" 0;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        font-variation-settings: "wght" 600, "wdth" 105, "ital" 0;
      }

      svg {
        margin-left: 20px;
        margin-right: 20px;
        height: 30px;
      }

    }
  }

  .no-result {
    display: none;
  }
}

.page-template-template-billetterie_landing {
  header {
    width: 100%;
    background-color: $dark;
    border-bottom: 1px solid $white;

    a {
      color: $white;
    }

    .login .line {
      background-color: $white;
    }

    .login_link {
      svg path {
        fill: $white;
      }

      ellipse {
        stroke: $white;
      }
    }

    .nav_mobile {
      background: $dark;
      color: $white;
    }

    .nav_btn_mobile {
      color: $white;
    }

    .nav_btn_mobile .burger div {
      background-color: $white;
    }

    .btn_round {
      border: 1px solid $white;

      &:before {
        background-color: $white;
      }

      &:hover svg path {
        fill: $dark;
      }
    }

    .logo {
      filter: grayscale(1) contrast(0) brightness(2);
    }
  }

  .toggle_concert {
    display: none;
  }

  .wrap {
    padding-right: 0px;
  }

  .template-billeterie .template-concerts {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    width: auto;
    z-index: 1;
    overflow: hidden;
    padding-left: 0px;
    border-bottom: 1px solid $white;
  }
}
